<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>

<style>
@import '@/static/css/app.css';
@media screen and (max-width : 300px) {
  .el-message {
    min-width: 10px !important;
    max-width: 150px !important;
    padding: 15px 10px 15px 10px !important;
    .el-message__content {
      font-size: 12px;
      font-weight: bold;
    }
    .el-message__icon {
      font-size: 16px;
    }
  }
}
@media screen and (min-width : 301px) and (max-width:400px) {
  .el-message {
    min-width: 10px !important;
    max-width: 280px !important;
    padding: 30px 10px 30px 10px !important;
    .el-message__content {
      font-size: 14px;
      font-weight: bold;
    }
    .el-message__icon {
      font-size: 20px;
    }
  }
}
@media screen and (min-width : 401px) and (max-width:820px) {
  .el-message {
    padding: 30px 15px 30px 15px !important;
    .el-message__content {
      font-size: 14px;
      font-weight: bold;
    }
    .el-message__icon {
      font-size: 20px;
    }
  }
}
@media screen and (min-width : 821px) {
  .el-message {
    padding: 30px 15px 30px 15px !important;
    .el-message__content {
      font-size: 16px;
      font-weight: bold;
    }
    .el-message__icon {
      font-size: 30px;
    }
  }
}
</style>
