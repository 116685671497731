<template>
    <div class="Email">
        <el-dialog class="dialogHead" :modal="false"  center title="Verify email address" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false" :visible="withdrawalVisible" @close="withdrawalVisible=false">
            <div class="Pai_title InfoRed">Email</div>
                <div class="Pai_input">
                <div class="Pai_flex">
                    <el-input class="email_width" v-model="ruleForm.email" controls-position="right"></el-input>
                    <el-button class="btnInfoRed content-left" :disabied="countdown>0"  @click="startCountdown()">{{ countdown > 0 ? `${countdown} Try again in seconds` : 'Send code' }}</el-button>
                </div>
                </div>
                <div class="Pai_title InfoRed">Verification code</div>
                <div class="Pai_input pai_width">
                <el-input v-model="ruleForm.VerificationCode" controls-position="right"></el-input>
                </div>
            <div slot="footer" class="dialog-footer">
              <el-button class="Pai_btn btnInfoRed ium" @click="drawalgoto()">Logout</el-button>
              <el-button class="Pai_btn btnInfoRed ium" @click="withdrawalSumbit()">Confirm</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script scoped>
import router from '../router';
export default {
  data() {
    return {
      countdown: 0, // 初始倒计时时间
      intervalId: null, // 存储interval的id
      ruleForm:{
        email: '',
        VerificationCode: ''
      },
      withdrawalVisible:false,
    }
  },
  mounted() {
  },
  methods: {
    openModal(){
      this.withdrawalVisible = true
    },
    drawalgoto() {
      this.$api.logOut({}).then(() => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        localStorage.removeItem('goods_detail');
        this.userInfo = {};
        this.notRead = {};
        router.push('/login')
        this.withdrawalVisible = false
      })
      
    },
    withdrawalSumbit(){
      if (this.ruleForm.email && this.ruleForm.VerificationCode) {
        this.$api.userProfile({
          email: this.ruleForm.email,
          code: this.ruleForm.VerificationCode
        }).then((res)=>{
          if (res.code ==1) {
            this.withdrawalVisible = false;
            this.$message.success(res.msg);
            // 获取旧值
            // var oldValue = localStorage.getItem('userInfo');
            // 清除旧值
            localStorage.removeItem('userInfo');
            // 设置新值
            localStorage.setItem('userInfo', JSON.stringify(res.data));
            // localStorage.setItem('userInfo', JSON.stringify(res.data));
          }else{
            this.$message.error(res.msg);
          }
        })
      }else{
        this.$message.warning('Email and verification code cannot be empty');
      }
      
    },
    startCountdown() {
      this.$api.sendEmail({
        email: this.ruleForm.email,
        type: 'changeemail'
      }).then((res)=>{
        if (res.code ==1) {
          this.$message.success(res.msg);
          if (this.intervalId) return;
          this.countdown = 60; // 设置倒计时的秒数
          this.intervalId = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown -= 1;
          } else {
            clearInterval(this.intervalId);
            this.intervalId = null;
          }}, 1000);
        }else {
          this.$message.error(res.msg);
        }
      })
      
    },
  }
}
</script>

<style scoped>
@import '@/static/css/amazon.css';
  .btnInfo{
    color: #FFF;
    background-color: #909399;
    border-color: #909399;
  }
  .btnInfoRed{
    color: #FFF;
    background-color: rgb(184,38,26);
    border-color: rgb(184,38,26);
  }
  .InfoRed{
    color: rgb(184,38,26);
    font-weight: bold;
  }
  .el-form /deep/ .el-form-item__content{
    margin-left: 100px;
  }
  .el-form /deep/ .el-form-item__label{
    font-size: 24px;
  }
  .ium{
    line-height: 0px;
  }
  @media screen and (max-width : 450px) {
    .content-left{
        margin: 5px;
    }
    .dialogHead /deep/ .el-dialog{
        width: 60%;
    }
    .dialogHead /deep/ .el-dialog__header{
        padding: 5px;
    }
    .dialogHead /deep/ .el-dialog__body{
        padding: 10px;
    }
    .pai_width{
        width: 60%;
    }
    .Pai_btn{
        width: 80%;
    }
    .email_width{
        width: 100%;
    }
    .Pai_flex{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .Email /deep/ .el-dialog__title{
        font-size: 14px;
    }
  }
  @media screen and (min-width : 451px) and (max-width:1199px) {
    .dialogHead /deep/ .el-dialog{
        width: 70%;
    }
    .content-left{
        margin-left: 0px;
    }
    .email_width{
        width: 60%;
    }
    .Pai_flex{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .Email /deep/ .el-dialog__title{
        font-size: 18px;
    }
  }
  @media screen and (min-width :1200px) {
    .content-left{
        margin-left: 20px;
    }
    .dialogHead /deep/.el-dialog{
        width: 40%;
    }
    .pai_width{
        width: 40%;
    }
    .email_width{
        width: 70%;
    }
    .Pai_flex{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .Email /deep/ .el-dialog__title{
        font-size: 23px;
    }
  }
</style>