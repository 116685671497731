import axios from '@/api/axios'
let api = {}
// 登录
/**
 * 登录
 * @param {string} obj 
 * @returns 
 */
api.login = (obj) => axios.post('/user/login', obj)

/**
 * apple登录链接
 * @param {string} obj
 * @returns
 */
api.loginAppleUrl = (obj) => axios.get('/user/login_apple_url',{
    params: obj
})
/**
 * 三方登录结果
 * @param {string} obj
 * @returns
 */
api.loginResult = (obj) => axios.post('/user/three_login_res', obj)

/**
 * google登录链接
 * @param {string} obj
 * @returns
 */
api.loginGoogleUrl = (obj) => axios.get('/user/login_google_url',{
    params: obj
})



/**
 * 注册会员
 * @param {string} obj
 */
api.register = (obj) => axios.post('/user/register', obj)


/**
 * 修改密码
 * @param {string} obj
 */
api.resetPwd = (obj) => axios.post('/user/resetpwd', obj)


/**
 * 发送验证码
 * @param {string} obj
 */
api.emailCode = (obj) => axios.post('/ems/send', obj)


/**
 * 获取配置项 关于我们等...
 */
api.baseConfig = (obj) => axios.get('/common/base_config',{
    params: obj
})

/**
 * 获取分类
 */
api.getCategory = (obj) => axios.get('/category/get_category',{
    params: obj
})

/**
 * 获取textarea
 */
api.getHomeText = (obj) => axios.get('/common/base_config',{
    params: obj
})
/**
 * 社交媒体列表
 */
api.mediaList = (obj) => axios.get('/socialmedia/get_list',{
    params: obj
})

/**
 * 博客列表
 */
api.blogList = (obj) => axios.get('/blog/get_list',{
    params: obj
})

/**
 * 博客详情
 */
api.blogInfo = (obj) => axios.get('/blog/get_info',{
    params: obj
})

/**
 * 雅虎分类
 */
api.yahooCategory = (obj) => axios.get('/exterior/yahoo_category',{
    params: obj
})

/**
 * 乐天分类
 */
api.rakutenCategory = (obj) => axios.get('/exterior/rakuten_category',{
    params: obj
})

/**
 * 乐天2分类
 */
api.rakuten2Category = (obj) => axios.get('/exterior/rakuten2_category',{
    params: obj
})

/**
 * 雅虎搜索
 */
api.yahooSearch = (obj) => axios.get('/exterior/yahoo_search',{
    params: obj
})

/**
 * 中间页
 */
api.yahooZj = (obj) => axios.get('/Exterior/quick_search_list',{
    params: obj
})

/**
 * 乐天搜索
 */
api.rakutenSearch = (obj) => axios.get('/exterior/rakuten_search',{
    params: obj
})

/**
 * 乐天2商品详情
 */
api.rakuten2Info = (obj) => axios.get('/exterior/rakuten2_info',{
    params: obj
})

/**
 * 乐天2搜索
 */
api.rakuten2Search = (obj) => axios.get('/exterior/rakuten2_search',{
    params: obj
})

/**
 * 最新公告
 */
api.newNotify = (obj) => axios.get('/notify/new_notify',{
    params: obj
})

/**
 * 用户信息
 */
api.userInfo = (obj) => axios.get('/user/get_info',{
    params: obj
})

/**
 * 公告列表
 */
api.notifyList = (obj) => axios.get('/notify/get_list',{
    params: obj
})

/**
 * 新增购物车
 */
api.addCart = (obj) => axios.post('/Shopcart/add', obj)

/**
 * 获取url的商品信息，价格，数量，库存，名称，图片
 */
api.addURLCart = (obj) => axios.post('/Exterior/get_url_info', obj)

/**
 * 发送验证码邮箱方式
 */
api.sendEmail = (obj) => axios.post('/ems/send', obj)

/**
 * 完善个人信息
 */
api.userProfile = (obj) => axios.post('/user/profile', obj)

/**
 * 购物车列表
 */
api.cartList = (obj) => axios.get('/Shopcart/list',{
    params: obj
})

/**
 * 购物车删除
 */
api.cartDel = (obj) => axios.post('/Shopcart/del', obj)

/**
 * 使用优惠券
 */
api.useCoupon = (obj) => axios.post('/Shopcart/use_coupon', obj)

/**
 * 创建订单获取商品列表
 */
api.cartInfo = (obj) => axios.post('/Shopcart/info', obj)

/**
 * pay now
 */
api.stripeOrderPay = (obj) => axios.post('/order/stripeOrderPay',obj)

/**
 * 创建订单-收货
 */
api.createOrderGet = (obj) => axios.post('/order/create_order_get', obj)

// 包裹列表
api.goodList = (obj) => axios.get('/order/good_list', {
    params: obj
})

/**
 * 订单列表
 * @param obj
 */
api.orderList = (obj) => axios.get('/order/order_list', {
    params: obj
})


/**
 * 获取日元汇率
 * @param obj
 */
api.getRate = (obj) => axios.get('/user/getRate', {
    params: obj
})

/**
 * 订单详情
 * @param obj
 */
api.orderInfo = (obj) => axios.get('/order/order_info', {
    params: obj
})
/**
 * 创建订单-修改订单
 */
api.editOrderPop = (obj) => axios.post('/order/edit_order_get', obj)
/**
 * 创建订单-订单在付款
 */
api.againOrder = (obj) => axios.post('/order/order_payment', obj)
/**
 * 创建订单-发货
 */
api.createOrderPop = (obj) => axios.post('/order/create_order_pop', obj)

/**
 * 订单评论
 * @param obj
 */
api.orderComment = (obj) => axios.post('/order/order_comment', obj)

/**
 * 用户地址列表
 * @param obj
 */
api.getAddress = (obj) => axios.get('/user/address_list', {
    params: obj
})

/**
 * 订单用户地址列表
 * @param obj
 */
api.getOrderAddress = (obj) => axios.get('/order/use_address_list', {
    params: obj
})

/**
 * 新增用户地址
 * @param obj
 */
api.addAddress = (obj) => axios.post('/user/add_address', obj)

/**
 * 修改用户地址
 * @param obj
 */
api.editAddress = (obj) => axios.post('/user/edit_address', obj)

/**
 * 修改用户地址
 * @param obj
 */
api.infoAddress = (obj) => axios.post('/user/address_info', obj)

/**
 * 删除用户地址
 * @param obj
 */
api.dieAddress = (obj) => axios.post('/user/del_address', obj)

/**
 * 修改用户信息
 * @param obj
 */
api.editUser = (obj) => axios.post('/user/profile', obj)

/**
 * 国家列表
 * @param obj
 */
api.countrieslist = (obj) => axios.post('/order/countries_list', obj)


/**
 * 地区 大洲/国家
 * @param obj
 */
api.getArea = (obj) => axios.get('/order/countries_and_continents', {
    params: obj
})

/**
 * 余额明细
 * @param obj
 */
api.getMoneyRecord = (obj) => axios.get('/user/money_record', {
    params: obj
})

/**
 * 获取充值地址
 * @param obj
 */
api.getAddMoneyUser = (obj) => axios.post('/order/user_add_money', obj)

/**
 * 支付运费
 * @param obj
 */
// api.orderFreightPay = (obj) => axios.post('/order/order_freight_pay', obj)
// api.orderPopStripe = (obj) => axios.post('/order/orderPopStripe', obj)

api.orderPayment = (obj) => axios.post('/order/order_payment', obj)
/**
 * 获取物流信息
 * @param obj
 */
api.getExpressList = (obj) => axios.post('/index/getExpressList', obj)


/**
 * 服务说明项
 */
api.getServerDescription = (obj) => axios.get('/category/server_description',{
    params: obj
})

/**
 * 运费计算器
 * @param obj
 */
api.areaPrice = (obj) => axios.get('/order/area_price', {
    params: obj
})

/**
 * 留言记录
 * @param obj
 */
api.leaveMessage = (obj) => axios.get('/user/leave_message', {
    params: obj
})

/**
 * 发送留言
 * @param obj
 */
api.sendLeaveMessage = (obj) => axios.post('/user/send_leave_message', obj)

/**
 * 未读消息数
 * @param obj
 */
api.notReadNum = (obj) => axios.get('/user/not_read_num', {
    params: obj
})

/**
 * 申请提现
 * @param obj
 */
api.withdrawalRequest = (obj) => axios.post('/user/withdrawal_request', obj)

/**
 * 获取订单评论
 */
api.commentList = (obj) => axios.get('/order/comment_list',{
    params: obj
})

/**
 * 公告列表
 */
api.cartCountValue = (obj) => axios.get('/order/cart_count_value',{
    params: obj
})

/**
 * 获取翻译
 */
api.fanyi = (obj) => axios.post('/exterior/fy_api', obj)

/**
 * 退出登录
 */
api.logOut = (obj) => axios.post('/user/logout', obj)

/**
 * 是否登录
 */
api.isLogin = (obj) => axios.post('/user/is_login', obj)

export default api;