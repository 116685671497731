import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

var router = new Router({
    // mode: 'hash',
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'mainIndex',
            component: () => import('@/pages/main/index.vue'),
            meta: {
                title: 'Home',
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/login/login.vue'),
            meta: {
                title: 'Login',
            }
        },
        {
            path: '/index',
            name: 'index',
            component: () => import('@/pages/index/index.vue'),
            meta: {
                title: 'Home',
            },
            children: [
                {
                    path: '/yaHu',
                    name: 'yaHu',
                    component: () => import('@/pages/main/yaHu.vue'),
                    meta: {
                        title: 'Yahoo',
                    }
                },
                {
                    path: '/leTian',
                    name: 'leTian',
                    component: () => import('@/pages/main/leTian.vue'),
                    meta: {
                        title: 'Rakuten',
                    }
                },
                {
                    path: '/leTian2',
                    name: 'leTian2',
                    component: () => import('@/pages/main/leTian2.vue'),
                    meta: {
                        title: 'Rakuten2',
                    }
                },
                {
                    path: '/leTianSecondHand',
                    name: 'leTianSecondHand',
                    component: () => import('@/pages/main/leTianSecondHand.vue'),
                    meta: {
                        title: 'Rakuten Rakuma',
                    }
                },
                {
                    path: '/rakutenSearch',
                    name: 'rakutenSearch',
                    component: () => import('@/pages/main/rakutenSearch.vue'),
                    meta: {
                        title: 'Product list',
                    }
                },
                {
                    path: '/rakuten2Search',
                    name: 'rakuten2Search',
                    component: () => import('@/pages/main/rakuten2Search.vue'),
                    meta: {
                        title: 'Product list',
                    }
                },
                {
                    path: '/yahooSearch',
                    name: 'yahooSearch',
                    component: () => import('@/pages/main/yahooSearch.vue'),
                    meta: {
                        title: 'Product list',
                    }
                },
                {
                    path: '/amazon',
                    name: 'amazon',
                    component: () => import('@/pages/main/amazon.vue'),
                    meta: {
                        title: 'Amazon',
                    }
                },{
                    path: '/Yauction',
                    name: 'Yauction',
                    component: () => import('@/pages/main/Yauction.vue'),
                    meta: {
                        title: 'Yauction',
                    }
                }, {
                    path: '/Paypay',
                    name: 'Paypay',
                    component: () => import('@/pages/main/Paypay.vue'),
                    meta: {
                        title: 'Paypay',
                    }
                }, {
                    path: '/BuyForMe',
                    name: 'BuyForMe',
                    component: () => import('@/pages/main/BuyForMe.vue'),
                    meta: {
                        title: 'BuyForMe',
                    }
                }, {
                    path: '/interpage',
                    name: 'interpage',
                    component: () => import('@/pages/main/interpage.vue'),
                    meta: {
                        title: 'Product list',
                    }
                },
                {
                    path: '/yahooGoodDetail',
                    name: 'yahooGoodDetail',
                    component: () => import('@/pages/main/yahooGoodDetail.vue'),
                    meta: {
                        title: 'Yahoo product details',
                    }
                },
                {
                    path: '/count',
                    name: 'count',
                    component: () => import('@/pages/main/count.vue'),
                    meta: {
                        title: 'Yahoo product details', //计算
                    }
                },
                {
                    path: '/letianGoodDetail',
                    name: 'letianGoodDetail',
                    component: () => import('@/pages/main/letianGoodDetail.vue'),
                    meta: {
                        title: 'Rakuten product details',
                    }
                },
                {
                    path: '/letian2GoodDetail',
                    name: 'letian2GoodDetail',
                    component: () => import('@/pages/main/letian2GoodDetail.vue'),
                    meta: {
                        title: 'Rakuten product details',
                    }
                },
                {
                    path: '/subscriptionPay',
                    name: 'subscriptionPay',
                    component: () => import('@/pages/main/subscriptionPay.vue'),
                    meta: {
                        title: 'Subscription payment',
                    }
                },
                {
                    path: '/editPay',
                    name: 'editPay',
                    component: () => import('@/pages/main/editPay.vue'),
                    meta: {
                        title: 'editPay payment',
                    }
                },

                {
                    path: '/noviceGuide',
                    name: 'noviceGuide',
                    component: () => import('@/pages/guide/noviceGuide.vue'),
                    meta: {
                        title: 'User guide',
                    }
                },
                {
                    path: '/notice',
                    name: 'notice',
                    component: () => import('@/pages/guide/notice.vue'),
                    meta: {
                        title: 'Notice',
                    }
                },
                {

                    path: '/blog',
                    name: 'blog',
                    component: () => import('@/pages/guide/blog.vue'),
                    meta: {
                        title: 'Blog',
                    }
                },
                {
                    path: '/blogDetails',
                    name: 'blogDetails',
                    component: () => import('@/pages/guide/blogDetails.vue'),
                    meta: {
                        title: 'Blog', //博客详情
                    }
                },
                {
                    path: '/blogDetail',
                    name: 'blogDetail',
                    component: () => import('@/pages/guide/blogDetail.vue'),
                    meta: {
                        title: 'Blog', //博客详情
                    }
                },
                {
                    path: '/aboutUs',
                    name: 'aboutUs',
                    component: () => import('@/pages/guide/aboutUs.vue'),
                    meta: {
                        title: 'About us', //About us
                    }
                },
                {
                    path: '/bottomContent',
                    name: 'bottomContent',
                    component: () => import('@/pages/guide/bottomContent.vue'),
                    meta: {
                        title: 'Details', //
                    }

                },
                {
                    path: '/serviceDescription',
                    name: 'serviceDescription',
                    component: () => import('@/pages/guide/serviceDescription.vue'),
                    meta: {
                        title: 'Service description',
                    }
                },
                {
                    path: '/personalCenter',
                    name: 'personalCenter',
                    component: () => import('@/pages/guide/personalCenter.vue'),
                    meta: {
                        title: 'My account',
                    },
                    redirect: 'cart',
                    children: [
                        {
                            path: '/cart',
                            name: 'cart',
                            component: () => import('@/pages/personalCenter/cart.vue'),
                            meta: {
                                title: 'Shopping cart',
                            }
                        },
                        {
                            path: '/contactService',
                            name: 'contactService',
                            component: () => import('@/pages/personalCenter/contactService.vue'),
                            meta: {
                                title: 'Contact customer service',
                            }
                        },
                        {
                            path: '/messageDynamics',
                            name: 'messageDynamics',
                            component: () => import('@/pages/personalCenter/messageDynamics.vue'),
                            meta: {
                                title: 'Message',
                            }
                        },
                        {
                            path: '/orderPackage',
                            name: 'orderPackage',
                            component: () => import('@/pages/personalCenter/orderPackage.vue'),
                            meta: {
                                title: 'Product list',
                            }
                        },
                        {
                            path: '/orderList',
                            name: 'orderList',
                            component: () => import('@/pages/personalCenter/orderList.vue'),
                            meta: {
                                title: 'Order list',
                            }
                        },
                        {
                            path: '/orderDetail',
                            name: 'orderDetail',
                            component: () => import('@/pages/personalCenter/orderDetail.vue'),
                            meta: {
                                title: 'Order details',
                            }
                        },
                        {
                            path: '/detailsWithdrawal',
                            name: 'detailsWithdrawal',
                            component: () => import('@/pages/personalCenter/detailsWithdrawal.vue'),
                            meta: {
                                title: 'Wallet',
                            }
                        },
                        {
                            path: '/account',
                            name: 'account',
                            component: () => import('@/pages/personalCenter/account.vue'),
                            meta: {
                                title: 'Account settings',
                            }
                        }
                    ]
                }
            ]
        },
        {
            name: 'orderDayinDetail',
            component: () => import('@/pages/personalCenter/orderDayinDetail.vue'),
            path: '/orderDayinDetail',
        },
    ]
})

export default router